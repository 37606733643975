import request from "@/utils/request";

// 韩语APP好剧榜列表
export function GoodPlayList(params) {
    return request({
        url: '/admin/korean_v3/goodPlayList',
        method: 'get',
        params
    })
}

// 添加韩语APP好剧榜
export function GoodPlayAdd(data) {
    return request({
        url: '/admin/korean_v3/goodPlayAdd',
        method: 'post',
        data
    })
}

// 编辑韩语APP好剧榜
export function GoodPlayEdit(data) {
    return request({
        url: '/admin/korean_v3/goodPlayEdit',
        method: 'put',
        data
    })
}

// 删除韩语APP好剧榜
export function GoodPlayDel(data) {
    return request({
        url: '/admin/korean_v3/goodPlayDel',
        method: 'delete',
        data
    })
}

// 好剧榜上传COS签名
export function getGoodPlayCosToken(params) {
    return request({
        url: '/admin/korean_v3/getGoodPlayCosToken',
        method: 'get',
        params
    })
}